import { Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectUrlService {

  constructor(private cookie: CookieService, private authService: AuthService) { }

  getRedirectUrl(): string {
    return this.authService.getRedirectUrl() || this.cookie.get('redirectUrl');
  }

  redirect(sessionStatus: any): void {
    const redirectUrl = this.getRedirectUrl();
    this.redirectToUrl(sessionStatus, redirectUrl);
  }

  redirectToUrl(sessionStatus: string, redirectUrl: string): void {
    if (!redirectUrl) {
      return;
    }
    window.location.href = this.buildRedirectUrl(redirectUrl, sessionStatus);
  }

  private buildRedirectUrl(redirectUrl: string, sessionStatus: string): string {
    return `${redirectUrl}&sessionStatus=${sessionStatus}`;
  }
}
