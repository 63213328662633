import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MonitorService } from 'app/core/monitor/monitor.service';
import { ErrorCode } from 'app/core/session/session-error';
import { KycRoute } from 'app/kyc-route.enum';

import { NotificationEvent } from '../core/notification/notification.model';
import { ParentNotifierService } from '../core/notification/parent-notifier.service';
import { RedirectUrlService } from '../core/redirect-url/redirect-url.service';
import { SessionStatusEnum } from '../core/session/session-status.enum';

@Injectable({
  providedIn: 'root'
})
export class FailureService {

  readonly handledErrorCodes = [ErrorCode.FORM_ERROR];

  constructor(
    private router: Router,
    private monitorService: MonitorService,
    private parentNotifierService: ParentNotifierService,
    private redirectUrlService: RedirectUrlService
  ) { }

  handleFailure(errorResponse: HttpErrorResponse): void {
    this.monitorService.error(`Received httpStatus: ${errorResponse.status} `);

    if (errorResponse.status === HttpStatusCode.Unauthorized) {
      this.parentNotifierService.notify({ event: NotificationEvent.SESSION_EXPIRED });
      this.redirectUrlService.redirect(SessionStatusEnum.EXPIRED);
    } else if (errorResponse.status === HttpStatusCode.BadRequest && this.handledErrorCodes.includes(errorResponse.error?.errorCode)) {
      // do nothing
    } else {
      this.parentNotifierService.notify({ event: NotificationEvent.FAILURE });
      
      const redirectUrl = this.redirectUrlService.getRedirectUrl();
      if (!redirectUrl) {
        this.router.navigate([KycRoute.FAILURE]);
      } else {
        this.redirectUrlService.redirectToUrl(SessionStatusEnum.FAILED, redirectUrl);
      }
    }
  }
}
