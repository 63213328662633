import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CookieService } from './core/cookie/cookie.service';
import { ResizeNotifierDirective } from './shared/resize/resize-notifier.directive';

@Component({
  selector: 'kyc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  hostDirectives: [ResizeNotifierDirective]
})
export class AppComponent {

  private readonly DEFAULT_LANGUAGE: string = 'en-GB';

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService
  ) {
    this.setupI18n();
  }

  private setupI18n(): void {
    const language = this.cookieService.get('lang');

    this.translateService.setDefaultLang(this.DEFAULT_LANGUAGE);

    if (language) {
      this.translateService.use(language);
    }
  }
}
