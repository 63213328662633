export interface Notification {
  event: NotificationEvent,
  body?: any
}

export enum NotificationEvent {
  KYC_RESIZE = 'KYC_RESIZE',
  FAILURE = 'FAILURE',
  ROUTE = 'ROUTE',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  COMPLETE = 'COMPLETE',
  CANCELLED ='CANCELLED'
}
