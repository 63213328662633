import { Injectable } from '@angular/core';
import { ManageService } from '@paysafe-tracking/error-monitoring';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  constructor(private errorMonitoring: ManageService) { }

  /**
   * Tracks ERROR event
   *
   * @param message - error message
   * @param payload - optional event data
   */
  error(message: string, payload?: any): void {
    this.errorMonitoring.logCustomError(message, payload);
  }
}
