import { Injectable } from '@angular/core';

import { Spinner } from './spinner.interface';
import { SpinnerService } from './spinner.service';

/**
 * This service holds the state of the global spinner
 * Every time some other service starts the spinner, the spinnerCounter is incremented, every time
 * external service stops it, the spinnerCounter is decremented.
 * The idea is, that the global spinner must not be visible only when the spinnerCounter is 0.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalSpinnerService {

  private spinnersCounter = 0;
  private spinner: Spinner;

  constructor(private spinnerService: SpinnerService) {
    this.spinner = spinnerService;
  }

  /**
   * Update the spinner in use.
   * This ultimately helps to have different spinner for different merchants.
   * The paysafe merchants use the spinner from @paysafe-ui/components.
   * With this method we can "lazy load" the library so other merchants
   * don't need to load @paysafe-ui/components/progress-spinner
   * 
   * @param updatedSpinner a spinner service
   */
  setSpinner(updatedSpinner: Spinner): void {
    this.spinner = updatedSpinner;
  }

  /**
   * Increment the spinnersCounter and shows the global spinner.
   */
  start(): void {
    if (this.spinnersCounter === 0) {
      this.spinner.start();
    }
    this.spinnersCounter++;
  }

  /**
   * Decrement the active spinnersCounter. Once it reach 0 hide the global spinner.
   */
  stop(): void {
    this.spinnersCounter--;
    if (this.spinnersCounter <= 0) {
      this.spinner.stop();
      this.spinnersCounter = 0;
    }
  }
}
