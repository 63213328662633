import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { KycRoute } from '../../kyc-route.enum';

@Component({
  selector: 'kyc-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.scss']
})
export class FailureComponent {

  constructor(private router: Router) { }

  continue(): void {
    this.router.navigate([KycRoute.ROOT]);
  }
}
