import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FailureComponent } from './failure/failure/failure.component';
import { KycRoute } from './kyc-route.enum';

const routes: Routes = [
  {
    path: KycRoute.ROOT,
    loadChildren: (): any => import('./screen/screen.module').then(m => m.ScreenModule)
  },
  {
    path: KycRoute.FAILURE,
    component: FailureComponent,
  },
  {
    path: '**',
    redirectTo: KycRoute.ROOT,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
