export interface SessionError {
  errorCode?: ErrorCode;
  details?: Detail[];
  message: string;
}

export enum ErrorCode {
  FORM_ERROR = '5001',
}

interface Detail {
  fieldName?: string;
  error?: string;
}
