/**
 * DO NOT STORE DEPLOYMENT ENVIRONMENT SETTINGS HERE!
 *
 * Note & How To Use
 *
 * THIS IS PRODUCTION READY FILE. DO NOT CHANGE IT.
 * During the build (`ng build ---prod`) this file will replace local `environment.ts`.
 * This file targets deployment specific fragment files stored in `/src/deployment/env/`.
 * All settings, ESPECIALLY DEPLOYMENT RELATED settings, should be defined in that fragment files.
 * Specific fragment will be included (SSI) by NGINX on the fly, depending on deployment environment.
 *
 * FOR DEVELOPMENT (LOCAL MACHINE) READY... visit `environment.ts` file.
 *
 * More detailed explanation on https://confluence.neterra.paysafe.com/pages/viewpage.action?pageId=164464273&src=contextnavpagetreemode
 */

export const environment = window['PS_SETTINGS'] || {};
