import { MonitoringConfiguration } from '@paysafe-tracking/error-monitoring';

import packageInfo from '../../../../package.json';

export const ERROR_MONITORING_CONFIGURATION: MonitoringConfiguration = {
  consumerId: 'PSFE_KYC_FE',
  appVersion: packageInfo.version,
  logEndpoint: 'api/kyc/monitor/error',
  emitEvents: false
};
