import { Injectable } from '@angular/core';

import { Spinner } from './spinner.interface';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService implements Spinner {

  // eslint-disable-next-line no-empty-function
  start(): void { }

  // eslint-disable-next-line no-empty-function
  stop(): void { }
}
