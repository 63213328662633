import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { NotificationEvent } from '../../core/notification/notification.model';
import { ParentNotifierService } from '../../core/notification/parent-notifier.service';

@Directive({
  selector: '[kycResizeNotifier]',
  standalone: true
})
export class ResizeNotifierDirective implements OnInit, OnDestroy {

  private observer: ResizeObserver;

  constructor(private elementRef: ElementRef, private parentNotifierService: ParentNotifierService) {
    this.observer = new ResizeObserver((entries: any) => {
      parentNotifierService.notify({
        event: NotificationEvent.KYC_RESIZE,
        body: { contentRect: entries[0].contentRect }
      });
    });
  }

  ngOnInit(): void {
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.elementRef.nativeElement);
  }
}
