import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable } from 'rxjs';

import { FailureService } from '../../failure/failure.service';
import { AuthService } from '../auth/auth.service';
import { CookieService } from '../cookie/cookie.service';
import { GlobalSpinnerService } from '../spinner/global-spinner.service';

const skipRequests: string[] = ['api/kyc/monitor'];

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private globalSpinner: GlobalSpinnerService,
    private cookieService: CookieService,
    private authService: AuthService,
    private failureService: FailureService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (skipRequests.some(url => request.url.includes(url))) {
      return next.handle(request);
    }

    const extendedRequest = this.extendRequestOptions(request);

    this.globalSpinner.start();

    return next.handle(extendedRequest).pipe(
      catchError((response: HttpErrorResponse) => {
        this.globalSpinner.stop();
        this.failureService.handleFailure(response);
        throw response;
      }),
      finalize(() => this.globalSpinner.stop())
    );
  }

  private extendRequestOptions(request: HttpRequest<any>): HttpRequest<any> {
    const headers: any = {};

    const xsrfToken: string = this.authService.getXsrfToken() || this.cookieService.get('XSRF-TOKEN');
    if (xsrfToken) {
      headers['X-XSRF-TOKEN'] = xsrfToken;
    }

    const accessToken = this.authService.getAccessToken();
    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return request.clone({
      setHeaders: headers
    });
  }
}
