import { Injectable } from '@angular/core';

import { Notification } from './notification.model';

@Injectable({
  providedIn: 'root'
})
export class ParentNotifierService {

  notify(notification: Notification): void {
    window.parent.postMessage(notification, '*');
  }
}
