import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaysafeWacWebErrorMonitoringLibFeModule } from '@paysafe-tracking/error-monitoring';
import { Observable } from 'rxjs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './core/auth/auth.service';
import { ERROR_MONITORING_CONFIGURATION } from './core/monitor/error.const';
import { RequestInterceptor } from './core/request-interceptor/request-interceptor';
import { FailureModule } from './failure/failure.module';
import { ThemeModule } from './theme/theme.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/common-fe/', '.json');
}

export function appInitializerFactory(authService: AuthService): () => Observable<unknown> | Promise<unknown> {
  return () => authService.init();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FailureModule,
    ThemeModule,
    PaysafeWacWebErrorMonitoringLibFeModule.forRoot(ERROR_MONITORING_CONFIGURATION),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AuthService],
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
