import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { SessionAuth } from './session-auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private sessionAuth: SessionAuth;

  constructor(private http: HttpClient) { }

  init(): Observable<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');
    const secureCodeHash = urlParams.get('secureCodeHash');

    if (sessionId && secureCodeHash) {
      return this.http.get<SessionAuth>('api/kyc/v1/session/authorization', { params: { secureCodeHash, sessionId } })
        .pipe(map(response => {
          this.sessionAuth = response;
          return null;
        }));
    }

    return of(null);
  }

  getXsrfToken(): string {
    return this.sessionAuth?.xsrfToken;
  }

  getAccessToken(): string {
    return this.sessionAuth?.accessToken;
  }

  getMerchantName(): string {
    return this.sessionAuth?.merchantName;
  }

  getRedirectUrl(): string {
    return this.sessionAuth?.redirectUrl;
  }
}

